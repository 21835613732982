import sentry from '@shared/services/sentry';
import { useQueryClient } from '@tanstack/react-query';
import { useAnalytics } from '../services/analytics/AnalyticsContext';
import useAuth from './use-auth';

const useLogout = () => {
  const { handleLogout } = useAuth();
  const { resetAnalytics } = useAnalytics();
  const queryClient = useQueryClient();

  const logout = async () => {
    try {
      handleLogout();
      queryClient.clear();
      resetAnalytics();
      sentry.resetUser();
    } catch (error) {
      sentry.log(error);
    }
  };
  return logout;
};

export default useLogout;
