import { AccountResponse } from '@shared/api/types';
import sentry from '@shared/services/sentry';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useAccount, useLogin, useLogout, useRefreshToken } from './api';

export type AuthContextType = {
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  user?: AccountResponse;
  loadingAuth: boolean;
  isAuthenticated: boolean;
  handleLogin: (email: string, password: string) => Promise<void>;
  handleLogout: () => void;
  refreshUser: () => Promise<void>;
};

const initialAuthContext: AuthContextType = {
  token: '',
  setToken: () => {},
  user: undefined,
  isAuthenticated: false,
  loadingAuth: true,
  handleLogout: async () => {},
  handleLogin: async () => {},
  refreshUser: async () => {},
};

const AuthContext = createContext<AuthContextType>(initialAuthContext);

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [token, setToken] = useState<string>('');
  const [refreshingToken, setRefreshingToken] = useState<boolean>(true);

  const {
    data: userData,
    isLoading: loadingUser,
    fetchStatus,
    refetch: refetchUser,
  } = useAccount(token);

  const { mutateAsync: refreshAccessToken } = useRefreshToken();
  const { mutateAsync: login } = useLogin();
  const { mutateAsync: logout } = useLogout();

  const refresh = useCallback(async () => {
    try {
      const newToken = await refreshAccessToken();
      if (newToken) setToken(newToken);
    } catch (err) {
      if (err.response?.status !== 401) {
        sentry.log(err);
      }
    } finally {
      setRefreshingToken(false);
    }
  }, [refreshAccessToken]);

  const handleLogin = useCallback(
    async (email: string, password: string) => {
      const { access } = await login({ email, password });
      setToken(access);
    },
    [login]
  );

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      setToken('');
    } catch (error) {
      sentry.log(error);
    }
  }, [logout]);

  const refreshUser = useCallback(async () => {
    try {
      await refetchUser();
      return Promise.resolve();
    } catch (error) {
      sentry.log(error);
      return Promise.reject();
    }
  }, [refetchUser]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (userData?.userEmail) {
      sentry.setUser(userData.userEmail);
    }
  }, [userData]);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user: userData,
        handleLogin,
        handleLogout,
        refreshUser,
        isAuthenticated:
          !refreshingToken && !loadingUser && !!token && !!userData?.uuid,
        loadingAuth: refreshingToken || (loadingUser && fetchStatus !== 'idle'),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
