import useTailwind from '@shared/styles/useTailwind';
import CloudSvg from './cloud.svg?react';

interface CloudIconProps {
  height?: number | string;
  width?: number | string;
  variant?: 'solid' | 'outline';
  className?: string;
  color?: string;
}

export const CloudIcon = ({
  height = '100%',
  width = '100%',
  variant = 'solid',
  className,
  color,
}: CloudIconProps) => {
  const { theme } = useTailwind();
  const iconColor = color || theme.colors.gray[700];
  const fill = variant === 'solid' ? iconColor : 'none';

  return (
    <CloudSvg
      height={height}
      width={width}
      className={className}
      fill={fill}
      stroke={iconColor}
    />
  );
};
