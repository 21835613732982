import useAuth from '@app/auth/use-auth';
import Page from '@app/components/layout/page/page';
import { useVerifyEmail } from '@shared/api';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import Loader from '@shared/components/loader';
import sentry from '@shared/services/sentry';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function VerifyEmailPage() {
  const { user, refreshUser } = useAuth();
  const { key } = useParams();
  const { mutateAsync: verifyEmail, isLoading, isError } = useVerifyEmail();
  const navigate = useNavigate();

  const handleVerifyEmail = useCallback(
    async (key: string) => {
      try {
        await verifyEmail({ data: { key } });
        await refreshUser();
        if (user!.hasCompletedSignup) return navigate('/');
        navigate('/sign-up/about-company');
      } catch (error) {
        sentry.log(error);
      }
    },
    [navigate, verifyEmail, user, refreshUser]
  );

  useEffect(() => {
    if (key) {
      handleVerifyEmail(key);
    }
  }, [key, handleVerifyEmail]);

  return (
    <Page name="Verify Email">
      <div className="flex size-full items-center justify-center p-2 md:p-0">
        {(!key || isError) && (
          <div className="flex flex-row items-center space-x-2">
            <Icons.warning className="size-6 text-primary-50" />
            <Text className="text-primary-50">
              There was an error verifying your email, please try again.
            </Text>
          </div>
        )}
        {isLoading && (
          <div className="space-y-6">
            <Text variant="cardTitle" className="text-primary-50">
              Verifying your email...
            </Text>
            <Loader color="teal" size="lg" />
          </div>
        )}
      </div>
    </Page>
  );
}
