import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import EditingProductCard from '@app/components/product-form/product-summary/editing-product-card';
import ProductSummaryForm from '@app/components/product-form/product-summary/product-summary-form';
import UnassessableProductCard from '@app/components/product-form/product-summary/unassessable-product-card';
import {
  useAssessProduct,
  useGetProduct,
  useSubmitProductAssessment,
} from '@shared/api';
import {
  ProductPackagingTypeSerializerNew,
  ProductProcessingTypeSerializerNew,
  ProductTransportTypeSerializerNew,
  ProductWithRelations,
} from '@shared/api/types';
import AssessmentPreviewSheet from '@shared/components/assessment-preview-sheet';
import Loader from '@shared/components/loader';
import { Button } from '@shared/components/ui/button';
import { useToast } from '@shared/components/ui/use-toast';
import { capitalizeFirstLetter, cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export type PPTTypes =
  | NonNullable<ProductWithRelations['productPackagingTypes']>[number]
  | NonNullable<ProductWithRelations['productProcessingTypes']>[number]
  | NonNullable<ProductWithRelations['productTransportTypes']>[number];

export const emptyPptRow = (type: string, includeSize?: boolean) => {
  return {
    [`${type}Type`]: '',
    proportionOfProduct: 100,
    ...(includeSize && { size: '' }),
  };
};

export const formattedTypes = <T extends PPTTypes>(
  type: 'packaging' | 'processing' | 'transport',
  data: T[]
) => {
  if (data.length === 0) return [emptyPptRow(type, type === 'packaging')];

  return data.map((item) => {
    return {
      ...item,
      proportionOfProduct: item.proportionOfProduct! * 100,
    };
  });
};

export default function ProductSummaryPage() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { product_id: productUuid } = useParams();
  const {
    data: product,
    isLoading: productLoading,
    status: productStatus,
  } = useGetProduct(productUuid!);
  const { mutateAsync: submitProduct, isLoading: submittingProduct } =
    useSubmitProductAssessment();
  const { mutateAsync: assessProduct, isLoading: assessingProduct } =
    useAssessProduct();

  const breadcrumbs = [
    {
      label: 'Products',
      url: '/products/',
    },
    {
      label: product?.name || '',
      url: `/products/${product?.uuid}`,
    },
    {
      label: 'Edit',
      url: ``,
    },
  ];

  const defaultValues = useMemo(() => {
    if (product) {
      const { servings, netWeight, ingredients } = product;
      return {
        servings,
        netWeight,
        ingredients,
        packaging: formattedTypes<ProductPackagingTypeSerializerNew>(
          'packaging',
          product.productPackagingTypes!
        ),
        processing: formattedTypes<ProductProcessingTypeSerializerNew>(
          'processing',
          product.productProcessingTypes!
        ),
        transport: formattedTypes<ProductTransportTypeSerializerNew>(
          'transport',
          product.productTransportTypes!
        ),
      } as any;
    }
  }, [product]);

  const handleCompleteAssessment = async () => {
    try {
      await assessProduct({ productUuid: productUuid! });
      navigate(`/products/${productUuid}/result`);
    } catch (error) {
      toast({
        title: 'An error occurred while assessing your product',
        description: 'Please try again or contact us if the error persists',
        variant: 'destructive',
      });
      sentry.log(error);
    }
  };

  const handleSubmitProduct = async () => {
    try {
      await submitProduct({ productUuid: productUuid! });
      navigate(`/products`);
      toast({
        title: 'Successfully submitted product for assessment',
        description:
          'This product has been submitted and is being processed by our team',
        variant: 'success',
      });
    } catch (error) {
      sentry.log(error);
      toast({
        title: 'An error occurred while submitting the product',
        description: 'Please try again or contact us if the error persists',
        variant: 'destructive',
      });
    }
  };

  if (!product) {
    navigate('/products');
    return false;
  }

  if (assessingProduct || productLoading) {
    return <Loader />;
  }

  return (
    <Page name="Product Summary" title="Product summary" status={productStatus}>
      <PageHeader
        name={product!.name}
        breadcrumbs={breadcrumbs}
        nameTag={
          product.status !== 'complete'
            ? product.status === 'in progress'
              ? 'Submitted'
              : capitalizeFirstLetter(product.status)
            : undefined
        }
        toolbar={
          <div className="text-nowrap">
            <AssessmentPreviewSheet
              productUuid={productUuid!}
              disabled={!product.isAssessable || product.status === 'complete'}
            />
            <Button
              onClick={() => {
                if (product?.isAssessable) return handleCompleteAssessment();
                return handleSubmitProduct();
              }}
              loading={assessingProduct || submittingProduct}
              disabled={
                assessingProduct ||
                submittingProduct ||
                product.status === 'in progress' ||
                product.status === 'complete'
              }
            >
              {product.isAssessable
                ? 'Complete assessment'
                : 'Submit for assessment'}
            </Button>
          </div>
        }
      />
      {product && (
        <div className="flex max-w-form flex-col gap-4 lg:max-w-full lg:flex-row lg:justify-between">
          <div className="h-min flex-1 lg:order-2 lg:max-w-[420px]">
            {product.status === 'editing' && <EditingProductCard />}
            {product.status !== 'editing' && !product.isAssessable && (
              <UnassessableProductCard />
            )}
          </div>
          <ProductSummaryForm
            defaultValues={defaultValues}
            product={product!}
            className={cn('flex w-full max-w-form flex-col items-center')}
          />
        </div>
      )}
    </Page>
  );
}
