import ProductProcessingPicker from '@app/components/product-form/product-processing-picker';
import { useGetAvailableProcessing } from '@shared/api';
import { AvailableProcessing } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import InputFormField from '@shared/components/form/input-form-field';
import { RepeatableRowComponentProps } from '@shared/components/form/types';
import { Button } from '@shared/components/ui/button';
import { FormLabel } from '@shared/components/ui/form';
import { Skeleton } from '@shared/components/ui/skeleton';
import { cn } from '@shared/lib/utils';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

interface ProductProcessingRowProps extends RepeatableRowComponentProps {}

export interface ProcessingOption {
  uuid: string;
  name: string;
}

type ProductProcessingRowData = {
  processingType: string;
  name: string;
};

export default function ProductProcessingRow({
  index,
  remove,
  setDisableAdd,
}: ProductProcessingRowProps) {
  const {
    data: availableProcessing,
    isLoading: processingOptionsLoading,
    isError: isProcessingOptionsError,
    error: processingOptionsError,
  } = useGetAvailableProcessing();
  const { getValues, watch } = useFormContext();

  const processingRows: ProductProcessingRowData[] = getValues('processing');
  const selectedProcessingRows = processingRows.map(({ name }, i) => ({
    name,
    index: i,
  }));

  const availableProcessingOptions: AvailableProcessing = useMemo(() => {
    const availableCustoms: AvailableProcessing['custom'] | undefined =
      availableProcessing?.custom.filter((p) => {
        const selectedRow = selectedProcessingRows.find(
          ({ name }) => name === p.name
        );
        return !selectedRow || selectedRow.index === index;
      });

    return {
      custom: availableCustoms,
    } as AvailableProcessing;
  }, [availableProcessing?.custom, index, selectedProcessingRows]);

  const watchProcessingName = watch(`processing.${index}.name`);

  useEffect(() => {
    processingOptionsError ||
    processingOptionsLoading ||
    availableProcessingOptions.custom.length === 1
      ? setDisableAdd(true)
      : setDisableAdd(false);
  }, [
    processingOptionsError,
    processingOptionsLoading,
    availableProcessingOptions,
    setDisableAdd,
  ]);

  if (processingOptionsLoading) {
    return (
      <div className="grid grid-cols-12 items-end gap-2">
        <Skeleton className="col-span-9 h-10 rounded-lg" />
        <Skeleton className="col-span-3 h-10 rounded-lg" />
      </div>
    );
  }

  if (isProcessingOptionsError || !availableProcessing) {
    throw new Error(
      `There was an error loading processing options ${processingOptionsError || 'No Data'}`
    );
  }

  return (
    <div className="grid grid-cols-12 items-start gap-2">
      <ProductProcessingPicker
        name={`processing.${index}.processingType`}
        availableProcessing={availableProcessingOptions}
        index={index}
        label={index === 0 ? 'Step' : ''}
        className="col-span-9"
      />

      <InputFormField
        name={`processing.${index}.proportionOfProduct`}
        label={index === 0 ? 'Percentage' : ''}
        disabled={!watchProcessingName}
        type="number"
        min={1}
        className="col-span-2"
        inputClassName="text-right"
      />

      <div className="col-span-1 grid items-end">
        {index === 0 && (
          <FormLabel className="mb-0 opacity-0">Remove</FormLabel>
        )}
        <Button
          type="button"
          variant="outline"
          size="sm"
          className={cn('size-10', {
            'mt-2': index === 0,
          })}
          onClick={() => {
            setDisableAdd(false);
            remove(index);
          }}
        >
          <Icons.x className="size-4" />
        </Button>
      </div>
    </div>
  );
}
