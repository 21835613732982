import { Icons } from '@shared/components/content/icons';
import { SIDEBAR_ICON_STYLE } from './styles';
import {
  NavCategory,
  NavItem,
  SidebarItem,
  SidebarItemPosition,
} from './types';

const HOME_ITEM: SidebarItem = {
  item: {
    title: 'Home',
    icon: <Icons.home className={SIDEBAR_ICON_STYLE} />,
    to: '/',
  } as NavItem,
  position: SidebarItemPosition.Top,
};

const PRODUCTS_ITEM: SidebarItem = {
  item: {
    title: 'Products',
    icon: <Icons.cloud className={SIDEBAR_ICON_STYLE} />,
    items: [
      {
        title: 'Create product',
        icon: <Icons.plus className={SIDEBAR_ICON_STYLE} />,
        to: '/products/create',
      },
      {
        title: 'All products',
        icon: <Icons.salad className={SIDEBAR_ICON_STYLE} />,
        to: '/products',
      },
      {
        title: 'Collections',
        icon: <Icons.tags className={SIDEBAR_ICON_STYLE} />,
        to: '/collections',
      },

      /* {
        title: 'Archive',
        icon: <Icons.archive className={SIDEBAR_ICON_STYLE} />,
        to: '/archive',
      }, */
    ],
  } as NavCategory,
  position: SidebarItemPosition.Top,
};

const REPORTS_ITEM: SidebarItem = {
  item: {
    title: 'Reports',
    icon: <Icons.lineChart className={SIDEBAR_ICON_STYLE} />,
    to: '/reports',
  } as NavItem,
  position: SidebarItemPosition.Top,
};

const HELP_CENTER_ITEM: SidebarItem = {
  item: {
    title: 'Help Center',
    icon: <Icons.lifeBuoy className={SIDEBAR_ICON_STYLE} />,
    to: 'https://myemissions.notion.site/Welcome-to-the-My-Emissions-Help-Centre-9fe6473d10d44b72813e7a31520cd74a?pvs=4',
    newTab: true,
  } as NavItem,
  position: SidebarItemPosition.Bottom,
};

const SETTINGS_ITEM: SidebarItem = {
  item: {
    title: 'Account',
    icon: <Icons.circleUser className={SIDEBAR_ICON_STYLE} />,
    to: '/settings',
  } as NavItem,
  position: SidebarItemPosition.Bottom,
};

const INGREDIENTS_ITEM: NavItem = {
  title: 'Ingredients',
  icon: <Icons.sprout className={SIDEBAR_ICON_STYLE} />,
  to: '/ingredients',
};

const PACKAGING_ITEM: NavItem = {
  title: 'Packaging',
  icon: <Icons.package className={SIDEBAR_ICON_STYLE} />,
  to: '/packaging',
};

const PROCESSING_ITEM: NavItem = {
  title: 'Processing',
  icon: <Icons.factory className={SIDEBAR_ICON_STYLE} />,
  to: '/processing',
};

const TRANSPORT_ITEM: NavItem = {
  title: 'Transport',
  icon: <Icons.truck className={SIDEBAR_ICON_STYLE} />,
  to: '/transport',
};

const createInputsItem = (items: NavItem[]): SidebarItem => ({
  item: {
    title: 'Inputs',
    icon: <Icons.puzzle className={SIDEBAR_ICON_STYLE} />,
    items,
  } as NavCategory,
  position: SidebarItemPosition.Top,
});

export const dishUserSidebarItems: SidebarItem[] = [
  HOME_ITEM,
  PRODUCTS_ITEM,
  createInputsItem([INGREDIENTS_ITEM, PACKAGING_ITEM]),
  REPORTS_ITEM,
  HELP_CENTER_ITEM,
  SETTINGS_ITEM,
];

export const productUserSidebarItems: SidebarItem[] = [
  HOME_ITEM,
  PRODUCTS_ITEM,
  createInputsItem([
    INGREDIENTS_ITEM,
    PACKAGING_ITEM,
    PROCESSING_ITEM,
    TRANSPORT_ITEM,
  ]),
  REPORTS_ITEM,
  HELP_CENTER_ITEM,
  SETTINGS_ITEM,
];
