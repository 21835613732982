import useAuth from '@app/auth/use-auth';
import { useGetProduct } from '@shared/api';
import Loader from '@shared/components/loader';
import { useParams } from 'react-router-dom';
import EditProductPage from './edit-product-page';
import ProductSummaryPage from './product-summary-page';

export default function EditProductRoute() {
  const { user } = useAuth();
  const { product_id: productUuid } = useParams();
  const { data: product, isLoading: productLoading } = useGetProduct(
    productUuid!
  );

  if (productLoading) return <Loader />;

  const editWithDishFlow =
    user?.productCategory === 'dish' && !product?.hasCustomPpt;

  return editWithDishFlow ? <EditProductPage /> : <ProductSummaryPage />;
}
