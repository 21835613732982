import { CollectionList, EmissionsCategoryEnum } from '@shared/api/types';
import { DataTable } from '@shared/components/data-table/data-table';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import { ColumnDef } from '@tanstack/react-table';

import Text from '@shared/components/content/text';
import { DateCell } from '@shared/components/data-table/cells/date-cell';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useGetStaticFilterOptions } from '@shared/components/data-table/hooks/use-get-static-filter-options';
import {
  DataTableFilterField,
  DataTableProps,
} from '@shared/components/data-table/types';
import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';
import { getUserFriendlyDate } from '@shared/lib/utils';
import { useNavigate } from 'react-router-dom';
import CollectionTableRowActions from './collection-table-row-actions';

interface CollectionTableProps extends Partial<DataTableProps> {
  collections: CollectionList[];
}

function getColumns(): ColumnDef<CollectionList>[] {
  return [
    {
      accessorKey: 'uuid',
      enableHiding: true,
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <HeaderCell column={column} name="Collection" textWhite />
      ),
      cell: ({ getValue, row }) => {
        const name = getValue<string>();
        const modifiedTime = getUserFriendlyDate(row.original.modifiedTime);

        return (
          <div>
            <Text className="min-w-[150px] max-w-[240px] truncate">{name}</Text>
            <Text variant="unit">Modified: {modifiedTime}</Text>
          </div>
        );
      },
    },
    {
      accessorKey: 'createdDate',
      header: ({ column }) => (
        <HeaderCell column={column} name="Creation date" textWhite />
      ),
      cell: ({ getValue }) => {
        return <DateCell date={getValue<string>()} />;
      },
    },
    {
      accessorKey: 'numProducts',
      header: ({ column }) => (
        <HeaderCell column={column} name="Number of products" textWhite />
      ),
      cell: ({ getValue }) => {
        return <NumberCell number={getValue<number>()} />;
      },
    },
    {
      accessorKey: 'averageEmissionsPerKg',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Average carbon intensity"
          unit={
            <span>
              (kgCO<sub>2</sub>e/kg<sub>food</sub>)
            </span>
          }
          textWhite
        />
      ),
      cell: ({ getValue }) => {
        const emissions = getValue<number>();
        if (emissions) return <NumberCell number={emissions} rounded />;
        return null;
      },
    },
    {
      accessorKey: 'averageEmissionsCategory',
      header: ({ column }) => (
        <HeaderCell column={column} name="Average rating" textWhite />
      ),
      cell: ({ getValue }) => {
        const rating = getValue<RatingCloudProps['rating']>();
        return <RatingCloud rating={rating} />;
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return <CollectionTableRowActions collection={row.original} />;
      },
    },
  ];
}

export default function CollectionTable({
  enableRowClick = true,
  enableRowActions = true,
  collections,
}: CollectionTableProps) {
  const navigate = useNavigate();

  const filterFields: DataTableFilterField<CollectionList>[] = [
    {
      label: 'Collections',
      value: 'name',
      placeholder: 'Search collections...',
    },
    {
      label: 'Average rating',
      value: 'averageEmissionsCategory',
      options: useGetStaticFilterOptions(EmissionsCategoryEnum),
    },
  ];

  const { table } = useDataTable({
    data: collections,
    columns: getColumns(),
    filterFields,
    columnVisibilityState: {
      uuid: false,
      actions: enableRowActions || false,
    },
  });

  return (
    <DataTable
      table={table}
      className="bg-gray-00"
      headerConfig={{ className: 'bg-primary' }}
      onRowClick={(row) => {
        if (!enableRowClick) return;
        navigate(`/collections/${row!.original.uuid}`);
      }}
    >
      <DataTableToolbar table={table} filterFields={filterFields} />
    </DataTable>
  );
}
