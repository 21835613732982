import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import EditDishForm, {
  editDishFormSchema,
} from '@app/components/product-form/edit-dish-form';
import { useGetProduct } from '@shared/api';
import { PatchedProductWithRelations } from '@shared/api/types';
import Loader from '@shared/components/loader';
import { useParams } from 'react-router-dom';

import { z } from 'zod';

export default function EditProductPage() {
  const { product_id: productUuid } = useParams();
  const { data: product, isLoading: productLoading } = useGetProduct(
    productUuid!
  );

  const getDefaultValues = (product: PatchedProductWithRelations) => {
    return {
      recipeName: product.name,
      servings: product.servings,
      netWeight: product.netWeight,
      ingredients: product.ingredients,
      packaging: product.productPackagingTypes?.map((packaging) => {
        return {
          uuid: packaging?.uuid,
          size: packaging?.size,
          name: packaging?.name,
          packagingType: packaging?.packagingType,
        };
      }),
    } as z.infer<typeof editDishFormSchema>;
  };

  const breadcrumbs = [
    {
      label: 'Products',
      url: '/products',
    },
    {
      label: `${product?.name}`,
      url: `/products/${productUuid}`,
    },
    {
      label: 'Edit',
      url: ``,
    },
  ];

  return (
    <Page name="Edit Product" title="Edit Product">
      {productLoading && <Loader />}
      {!productLoading && product && (
        <>
          <PageHeader name={product.name} breadcrumbs={breadcrumbs} />
          <div className="max-w-form">
            <EditDishForm
              defaultValues={getDefaultValues(product)}
              productUuid={productUuid!}
            />
          </div>
        </>
      )}
    </Page>
  );
}
