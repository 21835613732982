import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from '@sentry/react';
import { Toaster } from '@shared/components/ui/toaster';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Routes from './Routes';
import ApiProvider from './api/api-provider';
import { AuthProvider } from './auth/auth-provider';
import { AnalyticsProvider } from './services/analytics/AnalyticsProvider';
import theme from './styles/Styles';

function App() {
  return (
    <ErrorBoundary
      fallback={
        <div className="flex h-screen items-center justify-center">
          <h1>An error has occurred</h1>
        </div>
      }
    >
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <AnalyticsProvider>
            <ApiProvider>
              <Router>
                <Routes />
              </Router>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{
                  width: '420px',
                  zIndex: 100,
                }}
              />
              <Toaster />
            </ApiProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
