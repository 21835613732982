import useAuth from '@app/auth/use-auth';
import AlertMessage from '@app/components/AlertMessage';
import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import { useGetProduct, useGetProductResult } from '@shared/api';
import { PageTabs, Tab } from '@shared/components/page-tabs';
import { capitalizeFirstLetter } from '@shared/lib/utils';
import { Outlet, useParams } from 'react-router-dom';
import ProductDetailCollections from './components/product-detail-header-collections';
import ProductDetailName from './components/product-detail-name';
import ProductDetailPageToolbar from './components/product-detail-page-toolbar';

export default function ProductDetailPage() {
  const params = useParams();
  const productUuid = params.product_id;

  const { user } = useAuth();

  const { data: productResult, status: productResultStatus } =
    useGetProductResult(productUuid!);

  const { data: product } = useGetProduct(productUuid!);

  const tabs: Tab[] = [
    {
      title: 'Summary',
      href: `/products/${productUuid}`,
    },
    {
      title: 'Ingredients',
      href: `/products/${productUuid}/ingredients`,
    },
    {
      title: 'Processing',
      href: `/products/${productUuid}/processing`,
      hide: user.productCategory !== 'product' && !productResult?.hasCustomPpt,
      disabled: productResult?.processing.processingTypes.length === 0,
    },
    {
      title: 'Packaging',
      href: `/products/${productUuid}/packaging`,
      disabled: productResult?.packaging.packagingTypes.length === 0,
    },
    {
      title: 'Transport',
      href: `/products/${productUuid}/transport`,
      hide: user.productCategory !== 'product' && !productResult?.hasCustomPpt,
      disabled: productResult?.transport.transportTypes.length === 0,
    },
  ];

  const breadcrumb = [
    {
      label: 'Products',
      url: '/products/',
    },
    {
      label: product?.name || '',
      url: `/products/${product?.uuid}`,
    },
  ];
  const dateNow = new Date();

  const getExpiryDateCount = (date: string) => {
    const expiryDate = new Date(date);
    const dateNow = new Date();
    const diffTime = Math.floor(expiryDate.getTime() - dateNow.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const productExpiresSoon =
    productResult &&
    new Date(productResult.expiryDate) <
      new Date(dateNow.setMonth(dateNow.getMonth() + 3));

  return (
    <Page
      name="Product detail"
      status={productResultStatus}
      title={product?.name}
    >
      {productResult && product && (
        <>
          <PageHeader
            name={
              <ProductDetailName
                name={product.name}
                productUuid={product.uuid}
              />
            }
            toolbar={<ProductDetailPageToolbar product={productResult} />}
            nameTag={
              productResult.status !== 'complete'
                ? productResult.status === 'in progress'
                  ? 'Submitted'
                  : capitalizeFirstLetter(productResult.status)
                : undefined
            }
            breadcrumbs={breadcrumb}
            tabs={<PageTabs tabs={tabs} />}
            nameActions={
              <ProductDetailCollections
                productUuid={productResult.uuid}
                collections={productResult.collections}
              />
            }
          />

          {productResult.status === 'editing' && (
            <AlertMessage
              type="info"
              message="This product is currently being edited. This assessment refers to the product before editing. Complete the assessment to see the new results."
            />
          )}
          {productResult.status === 'expired' && (
            <AlertMessage
              type="error"
              message="Expired - Product assessment has expired. Assessment can no longer be used."
            />
          )}
          {productExpiresSoon &&
            getExpiryDateCount(productResult.expiryDate) > 0 && (
              <AlertMessage
                type="warning"
                message={`Expiring Soon - Product assessment will expire in ${getExpiryDateCount(productResult.expiryDate)} days.`}
              />
            )}
          {productResult.status === 'in progress' && (
            <AlertMessage
              type="warning"
              message={`In Progress - This product is currently being assessed.`}
            />
          )}
          <Outlet context={productResult} />
        </>
      )}
    </Page>
  );
}
