import ProductFPPTBarChart from '@app/components/charts/ProductFPPTBarChart';
import InfoPopOver from '@app/components/InfoPopOver';
import StatsCard from '@app/components/stats/StatsCard';
import {
  getUserFriendlyDate,
  roundNumberToNDecimalPlaces,
} from '@app/utils/helpers';
import { Delete, OutdoorGrill, Storefront } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import RatingsThresholdSlider from '@shared/components/ratings-thresholds-slider';
import RatingCloud from '@shared/components/ratings/rating-cloud';
import RatingLabel from '@shared/components/ratings/rating-label';
import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';

export default function ProductDetailSummary() {
  const product: any = useOutletContext();
  const [showMore, setShowMore] = React.useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const {
    assessmentDate,
    servings,
    expiryDate,
    systemBoundary,
    totalEmissions,
    totalEmissionsPerServing,
    totalEmissionsPerKg,
    lifeCycleStageEmissions: averageLifeCycleStageEmissionsPerKg,
    emissionsCategory,
    totalIngredientWeight,
    netWeight,
    status,
    collections,
    notesAndAssumptions,
    methodologyVersion,
  } = product;

  const FPPTBarChartData = [
    {
      lifeCycleStage: 'Farming',
      ingredientsEmissions: product.lifeCycleStageEmissions[0].emissions,
      productEmissions: 0,
      totalEmissions: product.lifeCycleStageEmissions[0].emissions,
    },
    {
      lifeCycleStage: 'Processing',
      ingredientsEmissions: product.ingredientProcessingEmissions,
      productEmissions: product.processing.processingEmissions,
      totalEmissions: product.lifeCycleStageEmissions[1].emissions,
    },
    {
      lifeCycleStage: 'Packaging',
      ingredientsEmissions: product.ingredientPackagingEmissions,
      productEmissions: product.packaging.packagingEmissions,
      totalEmissions: product.lifeCycleStageEmissions[2].emissions,
    },
    {
      lifeCycleStage: 'Transport',
      ingredientsEmissions: product.ingredientTransportEmissions,
      productEmissions: product.transport.transportEmissions,
      totalEmissions: product.lifeCycleStageEmissions[3].emissions,
    },
  ];

  return (
    <>
      <div>
        <Grid
          container
          columnSpacing={2}
          rowSpacing={2}
          sx={{
            filter: status === 'expired' ? 'grayscale(100%)' : '',
          }}
        >
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'left',
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: 'text.primary', marginTop: '12px' }}
              >
                Summary
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              justifyContent="center"
            >
              <Grid
                container
                columnSpacing={2}
                rowSpacing={1}
                item
                xs={12}
                sx={{
                  paddingTop: '0px',
                }}
              >
                <Grid item xs={12} sm={4}>
                  <Box>
                    <StatsCard
                      title="Total Emissions"
                      value={
                        totalEmissions !== null
                          ? roundNumberToNDecimalPlaces(
                              totalEmissions * 1000,
                              1
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          : 'N/A'
                      }
                      unit="gCO2e"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <StatsCard
                      title="Emissions Per Serving"
                      value={(totalEmissionsPerServing !== null
                        ? roundNumberToNDecimalPlaces(
                            totalEmissionsPerServing * 1000,
                            1
                          )
                        : 'N/A'
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      unit="gCO2e"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box mb="1rem">
                    <StatsCard
                      title="Carbon Intensity"
                      value={
                        totalEmissionsPerKg !== null
                          ? roundNumberToNDecimalPlaces(totalEmissionsPerKg, 2)
                          : 'N/A'
                      }
                      unit="kgCO2e/kgfood"
                      tooltip
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    width: '100%',
                    paddingX: '1.2rem',
                    paddingY: '1rem',
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb="0.5rem"
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.primary',
                        fontWeight: 'medium',
                      }}
                    >
                      Breakdown of Your Product Emissions by Life-Cycle Stage
                    </Typography>
                    {/* <InfoPopOver subjectMatter="Emissions Breakdown" /> */}
                  </Box>
                  <Box
                    sx={{
                      height: '300px',
                      marginBottom: '10px',
                      // width: "100%",
                    }}
                  >
                    {/* BARCHART GOES HERE */}
                    {status !== 'in progress' ? (
                      <ProductFPPTBarChart data={FPPTBarChartData} />
                    ) : (
                      <>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F5F5F5',
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'text.primary',
                              fontWeight: 'medium',
                            }}
                          >
                            Results are not available yet
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                  <Stack direction="row" spacing={4}>
                    <small style={{ marginTop: '12px', color: '#A1A1A1' }}>
                      Not included:
                    </small>
                    <div>
                      <Stack alignItems="center" justifyContent="center">
                        <Storefront style={{ color: '#A1A1A1' }} />
                        <small style={{ color: '#A1A1A1' }}>Store</small>
                      </Stack>
                    </div>
                    <div>
                      <Stack alignItems="center" justifyContent="center">
                        <OutdoorGrill style={{ color: '#A1A1A1' }} />
                        <small style={{ color: '#A1A1A1' }}>Cooking</small>
                      </Stack>
                    </div>
                    <div>
                      <Stack alignItems="center" justifyContent="center">
                        <Delete style={{ color: '#A1A1A1' }} />
                        <small style={{ color: '#A1A1A1' }}>Disposal</small>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            {notesAndAssumptions.length > 0 ? (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: '2rem',
                    marginBottom: '1rem',
                  }}
                >
                  <Typography variant="h6" sx={{ color: 'text.primary' }}>
                    Notes and Assumptions
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '10px',
                      width: '100%',
                      paddingRight: '1.2rem',

                      paddingY: '0.2rem',
                    }}
                  >
                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                      <ul>
                        {notesAndAssumptions.map((note: any, index: any) => {
                          return (
                            <li key={index}>
                              <Typography variant="body2">{note}</Typography>
                            </li>
                          );
                        })}
                      </ul>
                    </Typography>
                  </Box>
                </Grid>
              </>
            ) : (
              <> </>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '10px',
                width: '100%',
                overflow: 'hidden',

                padding: '1rem 0.5rem 2rem 0.5rem',
              }}
            >
              <Box>
                <Box
                  // display="flex"
                  justifyContent="center"
                  sx={{
                    padding: '0 0 1rem 0',
                    overflow: 'hidden',
                  }}
                >
                  {emissionsCategory === '' && status !== 'in progress' ? (
                    <Box
                      sx={{
                        position: 'relative',
                        top: '0',
                        right: '0',
                        float: 'right',
                      }}
                    >
                      <InfoPopOver subjectMatter="Carbon Rating" />
                    </Box>
                  ) : (
                    <> </>
                  )}

                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                      padding: '0 0 1rem 0',
                    }}
                  >
                    {emissionsCategory ? (
                      <RatingLabel rating={emissionsCategory} status={status} />
                    ) : (
                      <RatingCloud className="size-32" />
                    )}
                  </Box>
                </Box>
              </Box>

              {status === 'complete' && (
                <div
                  style={{
                    padding: '1rem',
                  }}
                >
                  <RatingsThresholdSlider
                    value={roundNumberToNDecimalPlaces(totalEmissionsPerKg, 2)}
                    methodologyVersion={methodologyVersion}
                  />
                </div>
              )}

              <Divider style={{ marginTop: '2rem' }} />

              <Box>
                <Grid
                  container
                  sx={{
                    padding: '1rem 0 0 0.5rem',
                  }}
                >
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InfoPopOver
                        subjectMatter="System Boundary"
                        width="15px"
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                          marginLeft: '0.3rem',
                        }}
                      >
                        System Boundary:
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        padding: 0,
                      }}
                    >
                      {systemBoundary.slice(0, 1).toUpperCase() +
                        systemBoundary.slice(1)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    padding: '1rem 0 0 0.5rem',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Product weight:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{}}>
                      {netWeight && totalIngredientWeight ? (
                        <>
                          {roundNumberToNDecimalPlaces(
                            totalIngredientWeight,
                            2
                          )}
                          g (net:{' '}
                          {roundNumberToNDecimalPlaces(netWeight * 1000, 2)}g)
                        </>
                      ) : totalIngredientWeight ? (
                        <>
                          {roundNumberToNDecimalPlaces(
                            totalIngredientWeight,
                            2
                          )}
                          g
                        </>
                      ) : (
                        'Pending'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    padding: '1rem 0 0 0.5rem',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Number of Servings:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{}}>
                      {(servings && roundNumberToNDecimalPlaces(servings, 1)) ||
                        'Pending'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    padding: '1rem 0 0 0.5rem',
                  }}
                >
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Box>
              <Divider />
              <Box>
                <Grid
                  container
                  sx={{
                    padding: '1rem 0 0 0.5rem',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Assessment Date:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        marginRight: '0.3rem',
                      }}
                    >
                      {assessmentDate
                        ? getUserFriendlyDate(assessmentDate)
                        : 'Pending'}
                    </Typography>
                    <InfoPopOver subjectMatter="No Date" width="15px" />
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    padding: '1rem 0 0 0.5rem',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Expiry Date:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        marginRight: '0.3rem',
                      }}
                    >
                      {expiryDate ? getUserFriendlyDate(expiryDate) : 'Pending'}
                    </Typography>
                    <InfoPopOver subjectMatter="No Date" width="15px" />
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    padding: '1rem 0 0 0.5rem',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Status:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          status === 'expired'
                            ? '#EA1919'
                            : status === 'in progress'
                              ? '#F2C94C'
                              : '#62AF85',

                        fontWeight: 'bold',
                      }}
                    >
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    padding: '1rem 0 0 0.5rem',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Collections:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{}}>
                      {collections.map((collection: any, i: any) => {
                        if (showMore) {
                          return (
                            <React.Fragment key={collection.uuid}>
                              <Link
                                to={`/collections/${collection.uuid}`}
                                style={{
                                  width: '100%',
                                  textDecoration: 'underline',
                                  color: '#333',
                                  // disable text wrap
                                  // whiteSpace: "nowrap",
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {collection.name}
                              </Link>
                              {i === collections.length - 1 ? null : ', '}
                            </React.Fragment>
                          );
                        } else {
                          if (i < 3) {
                            return (
                              <React.Fragment key={collection.uuid}>
                                <Link
                                  to={`/collections/${collection.uuid}`}
                                  style={{
                                    width: '100%',
                                    textDecoration: 'underline',
                                    color: '#333',
                                    // whiteSpace: "nowrap",
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {collection.name}
                                </Link>
                                {i === collections.length - 1 ? null : ', '}
                              </React.Fragment>
                            );
                          }
                        }
                        return null;
                      })}
                    </Typography>

                    {collections.length > 3 ? (
                      <Typography
                        variant="caption"
                        sx={{
                          float: 'right',
                          marginTop: '0.5rem',
                          cursor: 'pointer',
                          paddingRight: '0.5rem',
                          color: 'text.secondary',
                        }}
                        onClick={() => {
                          toggleShowMore();
                        }}
                      >
                        {showMore ? 'Show less' : 'Show more'}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
