import { EntityTypeEnum } from '@shared/api/types';
import { z } from 'zod';

export const reggieUuidSchema = z.string().optional();

export const recipeNameSchema = z
  .string({
    required_error: 'Please enter a recipe name',
  })
  .trim()
  .min(3, { message: 'Recipe name must be 3 or more characters long' });

export const servingsSchema = z.coerce.number().min(1, {
  message: 'Servings must be 1 or more',
});

export const netWeightSchema = z.union([
  z.coerce.number().min(0.001).optional().nullish(),
  z.literal(''),
]);

export const ingredientSchema = z.object({
  entityUuid: z.string().min(1, {
    message: 'Please select an ingredient',
  }),
  entityType: z
    .literal(EntityTypeEnum['base_food'])
    .or(z.literal(EntityTypeEnum['product'])),
  quantity: z.coerce.number().min(1),
  unitUuid: z.string().min(1),
});

export const packagingSchema = z.object({
  packagingType: z.string().min(1),
  size: z.string(),
  name: z.string().min(1, {
    message: 'Please select a packaging type ',
  }),
});

export const transportSchema = z.object({
  transportType: z.string().min(1),
  name: z.string().min(1, {
    message: 'Please select a transport type ',
  }),
  proportionOfProduct: z.coerce.number().min(0).max(100),
});

export const processingSchema = z.object({
  processingType: z.string().min(1),
  name: z.string().min(1, {
    message: 'Please select a processing type ',
  }),
  proportionOfProduct: z.coerce.number().min(0).max(100),
});

const collectionSchema = z.string();

// Pulled together
export const ingredientsSchema = z.array(ingredientSchema);
export const collectionsSchema = z.array(collectionSchema).optional();

export const productPackagingListSchema = z.array(
  packagingSchema
    .extend({
      proportionOfProduct: z.coerce
        .number()
        .min(1, {
          message: 'Percentage must be between 1 and 100',
        })
        .max(100, {
          message: 'Percentage must be between 1 and 100',
        }),
    })
    .superRefine((fields, ctx) => {
      if (fields.packagingType === '') {
        return ctx.addIssue({
          code: 'custom',
          message: 'Please enter a size',
          path: ['size'],
        });
      }
    })
);

export const packagingListSchema = z
  .array(
    packagingSchema.superRefine((fields, ctx) => {
      if (fields.packagingType === '') {
        return ctx.addIssue({
          code: 'custom',
          message: 'Please enter a size',
          path: ['size'],
        });
      }
    })
  )
  .optional();

export const transportListSchema = z.array(transportSchema);
export const processingListSchema = z.array(processingSchema);
